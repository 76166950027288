import axios, { AxiosError } from 'axios'
import * as Sentry from '@sentry/nextjs'
import { config } from 'config'

// cookie から manavi_session を取得する
// TODO: 別のファイルに移動する
const getSessionCookie = (): string | undefined => {
  const cookie = document.cookie
  const cookieArray = cookie.split('; ')
  const sessionCookie = cookieArray.find((item) => item.startsWith('manavi_session'))
  if (!sessionCookie) return
  const [, session] = sessionCookie.split('=')
  return session
}
export function sentrySetUser(userId: number, role?: number) {
  if (config.ENABLE_SENTRY) {
    Sentry.configureScope((scope) => {
      scope.setUser({ userId, role, host: window.location.host })
    })
  }
}

export function sendSentryLog(e: Error | AxiosError) {
  if (!config.ENABLE_SENTRY) return

  if (axios.isAxiosError(e)) {
    let contexts = {}
    const response = e.response
    const endpoint = response?.config?.url ?? ''
    const status = response?.status ?? ''
    const method = response?.config?.method ?? ''

    contexts = { response }

    Sentry.withScope((scope) => {
      scope.setFingerprint(['{{ default }}', endpoint, String(status), method])
      Sentry.captureException(e, {
        tags: {
          cookie: getSessionCookie(),
        },
        contexts,
      })
    })
  } else {
    Sentry.captureException(e, {
      tags: {
        cookie: getSessionCookie(),
      },
    })
  }
}
