import { createTheme } from '@mui/material/styles'
import type { ThemeOptions } from '@mui/material'
import mediaQuery from 'css-mediaquery'
import type { Colors } from 'types/theme'
import { notoSansJP } from 'pages/_app'

type ThemeColors = {
  primary: Colors
  secondary: Colors
  tertiary: Colors
  danger: Colors
  grey: Colors
  yellow: Colors
  blue: Colors
  white: Colors
  green: Colors
  pink: Colors
}

const themeColors: ThemeColors = {
  primary: {
    base: '#0284FE',
    lightness1: '#4EA9FE',
    lightness2: '#93CAFE',
    lightness3: '#C9E4FE',
    lightness4: '#E0EFFE',
    lightness5: '#E5F0F9',
    darkness1: '#323C65',
    darkness2: '#003260',
    darkness3: '#001E3A',
    darkness4: '#005BB1',
  },
  secondary: {
    base: '#2EC2A0',
    lightness2: '#A8E5D7',
    lightness3: '#D3F2EB',
    lightness4: '#E6F7F3',
  },
  tertiary: {
    base: '#5E6993',
    lightness1: '#9298B6',
    lightness2: '#BABED1',
    lightness3: '#DCDEE8',
    lightness4: '#F3F6F9',
    darkness1: '#707485',
    darkness2: '#495173',
    darkness3: '#333851',
    darkness4: '#323C44',
  },
  danger: {
    base: '#FE025F',
    lightness1: '#FF004D',
    lightness2: '#F78D8D',
    lightness3: '#FEC8D0',
    lightness4: '#FEE0E1',
    lightness5: '#FFEFF0',
    darkness1: '#F57171',
    darkness2: '#FD5386',
    darkness3: '#fe025e',
    darkness4: '#FE92A2',
    darkness5: '#B10035',
  },
  grey: {
    base: '#A5A5A5',
    lightness1: '#E0E0E0',
    lightness2: '#EDEDED',
    lightness3: '#EEEEEE',
    lightness4: '#FAFAFA',
    lightness5: '#F3F3F3',
    darkness1: '#DDDDDD',
    darkness2: '#6C757D',
    darkness3: '#8596A7',
    darkness4: '#EBECF1',
    darkness5: '#565656',
  },
  yellow: {
    base: '#ffaf24',
    lightness1: '#FE892E',
    lightness5: '#FFF4E5',
    darkness1: '#DB8F18',
  },
  blue: {
    base: '#EBF5FF',
    lightness1: '#CCE6FF',
    lightness2: '#C2E0FC',
    lightness3: '#005BB1',
    darkness1: '#4370C3',
    darkness2: '#4146A6',
  },
  white: {
    base: '#FFFFFF',
    lightness1: '#FBFCFD',
    lightness4: '#FFFFFF8C',
  },
  green: {
    base: '#28CD6A',
    lightness1: '#1FC58E',
    lightness2: '#18A556',
    lightness3: '#7FBF3F',
    lightness4: '#FAFDFB',
    lightness5: '#EDF7ED',
    darkness1: '#669966',
    darkness3: '#208970',
    darkness4: '#1A5555',
  },
  pink: {
    base: '#F441AE',
    lightness1: '#FAF5F8',
  },
}

// FYI: https://mui.com/customization/default-theme/#explore
const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: 'inherit',
    h1: {
      fontSize: '32px',
      fontWeight: 700,
      lineHeight: '130%',
    },
    h2: {
      fontSize: '28px',
      fontWeight: 700,
      lineHeight: '130%',
    },
    h3: {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '130%',
    },
    h4: {
      fontSize: '22px',
      fontWeight: 700,
      lineHeight: '130%',
    },
    h5: {
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '130%',
    },
    h6: {
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '130%',
    },
  },
  palette: {
    text: {
      primary: themeColors.tertiary.darkness3,
    },
    primary: {
      main: themeColors.primary.base,
    },
    secondary: {
      main: themeColors.secondary.base,
    },
    tertiary: {
      main: themeColors.tertiary.base,
      light: themeColors.tertiary.lightness1,
      dark: themeColors.tertiary.darkness3,
    },
    danger: {
      main: themeColors.danger.base,
    },
    error: {
      main: themeColors.danger.base,
    },
    action: {
      disabled: themeColors.grey.base,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {},
      styleOverrides: {
        contained: {
          color: 'white',
        },
        outlined: {
          backgroundColor: 'white',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          // NOTE: Card padding should be removed someday because it was not consistent in the design...
          padding: 45,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .Mui-error': {
            color: themeColors.danger.base,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          marginBottom: 4,
          display: 'inline-flex',
        },
        asterisk: {
          minWidth: 'fit-content',
          color: 'transparent',
          fontWeight: 700,
          '&.Mui-error': {
            color: 'transparent',
          },
          '&::after': {
            background: themeColors.danger.lightness1,
            content: '"必須"',
            fontSize: 12,
            color: 'white',
            borderRadius: 2,
            padding: '4px 8px',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 14,
          marginLeft: 0,
        },
      },
    },
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        sizeSmall: {
          fontSize: 10,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: 'none',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          fontFamily: notoSansJP.style.fontFamily,
        },
      },
    },
  },
  backgroundColor: '#F3F7FB',
  ...themeColors,
}

const mobileSsrMatchMedia = (query: string) => ({
  matches: mediaQuery.match(query, {
    // The estimated CSS width of the browser.
    width: '0px',
  }),
})

const desktopSsrMatchMedia = (query: string) => ({
  matches: mediaQuery.match(query, {
    // The estimated CSS width of the browser.
    width: '1024px',
  }),
})

// https://mui.com/material-ui/react-use-media-query/ see more
const themeSp = createTheme({
  ...themeOptions,
  components: {
    ...themeOptions.components,
    MuiUseMediaQuery: {
      defaultProps: {
        ssrMatchMedia: mobileSsrMatchMedia,
      },
    },
  },
})
const theme = createTheme({
  ...themeOptions,
  components: {
    ...themeOptions.components,
    MuiUseMediaQuery: {
      defaultProps: {
        ssrMatchMedia: desktopSsrMatchMedia,
      },
    },
  },
})

export { theme, themeSp }
